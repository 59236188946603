<script lang="ts">
	import { ProgressRadial } from '@skeletonlabs/skeleton';
	export let href: string = '/';

	let isLoading: boolean = false;

	async function handleClick() {
		isLoading = true;
		setTimeout(() => {
			isLoading = false;
		}, 2000);
	}
</script>

<a {href} {...$$restProps} on:click={handleClick}>
	{#if isLoading}
		<ProgressRadial width="w-6" />
	{:else}
		<slot />
	{/if}
</a>
